import React, { useEffect } from "react"
import styled from "styled-components"

import Footer from "../../components/footer"
import Layout from "../../components/layout"
import { Section, SectionInner } from "../../components/section"
import Header from "../../components/adventkalender/header"
import SEO from "../../components/adventkalender/seo"

import { dlPush } from "../../events"


const Breadcrumb = styled.ol`
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;

    a {
      display: inline-block;
      margin-right: 16px;
      position: relative;

      &:after {
        background-image: url(${require('../../images/breadcrumb-separator@2x.png')});
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 6px 12px;
        bottom: 0;
        content: '';
        display: block;
        position: absolute;
        right: -12px;
        top: 0;
        width: 16px;
      }
    }
  }
`


const SectionRules = styled(Section).attrs({
  bgAlt: true
})`
  font-size: 18px;
  padding-bottom: 136px;
  padding-top: 24px;
  text-align: left;

  @media (max-width: 1339px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  h1 {
    @media (max-width: 409px) {
      font-size: 16px;
    }
  }

  h2 {
    margin-bottom: 16px;
    margin-top: 80px;
  }

  h3 {
    margin-bottom: 16px;
    margin-top: 32px;
  }
`


export default ({ location }) => {
  useEffect(() => {
    dlPush({
      event: "virtualPageView",
      pageUrlPath: location.pathname,
      pageType: "home",
      referrer: window.document.referrer,
      userId: null,
      locationAddress: null,
      locationArea: null,
      locationCity: null,
      locationLat: null,
      locationLon: null,
      locationCountry: "Austria",
      eventFwfFeatures:  null,
      userLoggedIn: false
    })
  }, [])

  return <Layout>
    <SEO
      title="Teilnahmebedingungen – mjam Adventkalender 2019"
      description="Der mjam Adventkalender 2019:⭐ Essen bestellen & täglich Sofortgewinn absahnen! ⭐Die Teilnahmebedingungen findest du hier."
    />

    <Header
      header={<h1>mjam Adventkalender 2019<br />Teilnahmebedingungen</h1>} />
    <SectionRules>
      <SectionInner>
        <Breadcrumb vocab="https://schema.org/" typeof="BreadcrumbList">
          <li property="itemListElement" typeof="ListItem">
            <a property="item" typeof="WebPage"
                href="https://www.mjam.net">
              <span property="name">Mjam</span>
            </a>
            <meta property="position" content="1" />
          </li>
          <li property="itemListElement" typeof="ListItem">
            <a
                property="item"
                typeof="WebPage"
                href="https://www.mjam.net/info/adventkalender/">
              <span property="name">Adventkalender</span>
            </a>
            <meta property="position" content="2" />
          </li>
          <li property="itemListElement" typeof="ListItem">
            <span property="name">Teilnahmebedingungen</span>
            <meta property="position" content="3" />
          </li>
        </Breadcrumb>

        <h2>Teilnahmebedingungen des mjam Adventkalenders</h2>

        <h3>1. Veranstalter</h3>
        <p>Dieses Gewinnspiel wird von der mjam GmbH, Mayergasse 14/ 1+2, 1020 Wien veranstaltet.</p>

        <h3>2. Teilnahme</h3>
        <p>Am Gewinnspiel kann jeder teilnehmen, der zwischen dem 1. Dezember 2019 und dem 24. Dezember 2019 bei mjam unter www.mjam.at oder über die App von mjam eine Bestellung durchgeführt hat, über 18 Jahre alt ist und seinen Wohnsitz in Österreich hat. Die Teilnahme erfolgt durch Anklicken eines entsprechenden Banners am Ende des Bestellvorgangs auf der Bestellbestätigungsseite.</p>
        <p>Jede Bestellung zählt als erneute Teilnahme und wird bei der Gewinnermittlung einzeln berücksichtigt.</p>
        <p>Mitarbeiter der mjam GmbH, der Delivery Hero Austria GmbH und der Delivery Hero AG sowie deren Angehörige sind von der Teilnahme ausgeschlossen.</p>

        <h3>3. Gewinne, Ermittlung und Benachrichtigung der Gewinner</h3>
        <p>Es gibt zwei verschiedene Gewinnklassen, zum einen Sofortgewinne und zum anderen Hauptgewinne:</p>
        <p><u>Sofortgewinne:</u> Jede Bestellung, welche am Gewinnspiel teilnimmt, erhält einen Sofortgewinn. Hierbei handelt es sich um Gutscheine und/oder Ermäßigungen von der mjam GmbH und deren Partnern (FlixBus, Teufel, Mister Spex, J. Hornig, Babbel und BookBeat).</p>
        <p>Jeder Kunde bekommt seinen Sofortgewinn unverzüglich nach der Bestellung und dem Klick auf das Aktionsbanner als zusätzliches, zweites Banner angezeigt und erhält diesen zusätzlich per E-Mail zugeschickt. Hierzu wird die bei der mjam GmbH für die Essensbestellung hinterlegte E-Mail-Adresse verwendet. Bereits versendete Gewinne und Gutscheincodes können nicht im Nachhinein an eine andere Mailadresse versendet werden.</p>
        <p><u>Hauptgewinn-Pakete:</u> Im Rahmen der Aktion gibt es vier Hauptgewinn-Pakete. Unter allen Teilnehmern (allen, die das Banner geklickt und einen Sofortgewinn erhalten haben) werden spätestens am Ende der Kampagnenlaufzeit die Gewinner der Hauptpreise zufällig per Los ermittelt.</p>
        <h4>Für die Hauptgewinn-Pakete gilt:</h4>
        <p>Die Gewinner der Hauptpreise werden unverzüglich nach der Verlosung, spätestens jedoch bis zum 31.01.2020, benachrichtigt, wozu die bei der mjam GmbH hinterlegte E-Mail-Adresse verwendet wird.</p>
        <p>Zur Gewinnannahme ist eine E-Mail an marketing@mjam.de zu schreiben, die den vollständigen Namen und die Anschrift des Gewinners enthält. Sollte der jeweils benachrichtigte Gewinner nicht innerhalb von 14 Tagen auf die Gewinnbenachrichtigung antworten und den Gewinn unter Angabe seiner Adressdaten annehmen, verfällt der Anspruch auf den Gewinn. Die mjam GmbH behält sich vor, den jeweiligen Gewinn in einem solchen Fall dann gegebenenfalls an einen anderen Teilnehmer, der die Voraussetzungen für die Teilnahme am Gewinnspiel erfüllt hat, erneut zu verlosen. Der Gewinner muss die Anlieferung der Hauptgewinn-Pakete per Post ermöglichen.</p>

        <h3>4. Einlösungsbedingungen, Kosten</h3>
        <p>Eine Barauszahlung der Gewinne oder eine Auszahlung in Sachwerten ist nicht möglich. Gewinnansprüche können nicht auf Dritte übertragen werden. Jegliche Form des Weiterverkaufs der Gewinne oder der Weiterverlosung der Gewinne ist untersagt. Sollte dies dennoch geschehen, behält sich mjam GmbH das Recht vor, den jeweiligen Gewinn zurückzuziehen und diesen gegebenenfalls an einen anderen Teilnehmer, der die Voraussetzungen für die Teilnahme am Gewinnspiel erfüllt hat, erneut zu verlosen.</p>

        <h4>Sofortgewinne:</h4>
        <p>Mit dem Gutscheincode bekommst du 1 Gratismonat BookBeat Premium (Wert 19,90 Euro). Der Gutscheincode kann eingelöst werden auf: <a href="https://www.bookbeat.de/mjam" target="_blank" rel="noopener noreferrer">www.bookbeat.de/mjam</a> und gilt nur für Neukunden, nur 1x/Person und bis zum 31.12.2019. Der Gratismonat geht bei Nicht-Kündigung in ein monatlich kündbares Abo über. Es gelten die <a href="https://www.bookbeat.de/agb" target="_blank" rel="noopener noreferrer">allgemeinen Geschäftsbedingungen</a> der BookBeat GmbH, Friedrichstraße 9, 80801 München. Der Gutschein kann weder verkauft noch bar bezahlt werden. Der Gewinn wird von der BookBeat GmbH gestellt.</p>

        <p>3 Monate Babble geschenkt bei Abschluss eines 3-Monatsabos:<br />Mit dem Code erhältst du beim Abschluss eines 3-Monatsabos zum Erlernen einer der 14 verfügbaren Sprachen zusätzlich 3 weitere Monate zum Preis von nur 29,97 € (inkl. USt.). Nach den ersten 6 Monaten (3+3) verlängert sich das Abonnement automatisch um weitere 3 Monate für den oben genannten Betrag bis zur Kündigung des Abos, welche bis 48 Stunden vor Ablauf der jeweiligen Laufzeit möglich ist. Angebot gültig bis 31. März 2020. Um den Gutschein einzulösen, musst du bei Babbel registriert sein. Informationen zum Widerrufsrecht für Verbraucher und zu den Allgemeinen Geschäftsbedingungen findest du unter <a href="https://about.babbel.com/de/terms/" target="_blank" rel="noopener noreferrer">https://about.babbel.com/de/terms/</a>. Ein Angebot der Lesson Nine GmbH, Max-Beer-Str. 2, 10119 Berlin, support@babbel.com. Der Gewinn wird von der Lesson Nine GmbH gestellt.</p>
        <p>10 % Rabattgutschein für Mister Spex:<br />Der Code ist bis zum 31.01.2020 auf www.misterspex.at einlösbar und nicht mit anderen Aktionen kombinierbar. Eine kommerzielle Veröffentlichung des Rabatts (z.B. auf Gutschein-Websites) ist nicht gestattet. Es gelten die <a href="https://www.misterspex.at/rechtliche-hinweise/agb" target="_blank" rel="noopener noreferrer">allgemeinen Geschäftsbedingungen</a> der Mister Spex GmbH, Greifswalder Straße 156, 10409 Berlin. Keine Barauszahlung. Der Gewinn wird von der Mister Spex GmbH gestellt.</p>
        <p>15 % Rabattgutschein für J. Hornig:<br />Der Gutscheincode ist pro Person einmalig und nur im J. Hornig Onlineshop unter www.jhornig.com einlösbar und kann nicht mit anderen Gutscheinen kombiniert werden. Der Gutscheincode ist an keinen Mindestbestellwert gebunden. Der Gutscheincode kann auf das gesamte J. Hornig Onlineshop-Produktsortiment angewendet werden. Davon ausgenommen sind Gutscheine und Barista-Kurse jeglicher Art. Keine Barauszahlung. Eine kommerzielle Veröffentlichung des Rabatts (z.B. auf Gutschein-Websites) ist nicht gestattet. Gültig bis 31.01.2020. Um den Gutschein einzulösen, gib den Code im J. Hornig Warenkorb auf www.jhornig.com ein und klicke auf „Gutschein anwenden“. Es gelten die <a href="https://www.jhornig.com/agb/" target="_blank" rel="noopener noreferrer">allgemeinen Geschäftsbedingungen</a> der J. Hornig GmbH, Waagner-Biro-Straße 39-41, 8020 Graz. Der Gewinn wird von der J. Hornig GmbH gestellt.</p>
        <p>10 % Rabattgutschein für FlixBus:<br />Der Gutschein ist ohne Mindestbestellwert gültig bis 31.03.2020 und kann für eine Reise zwischen dem 07.01.2020 und 31.03.2020 eingelöst werden. Es gelten die <a href="https://www.flixbus.de/agb" target="_blank" rel="noopener noreferrer">allgemeinen Geschäftsbedingungen</a> der FlixMobility GmbH, Birketweg 33, 80639 München. Der Gutschein kann weder verkauft noch bar bezahlt werden und ist ausschließlich in der FlixBus App für Android und iOS einlösbar. Nur ein Gutschein pro Person und Buchung einlösbar. Der Gewinn wird von der FlixMobility GmbH gestellt.</p>
        <p>10 % Rabattgutschein für Teufel:<br/>10 % Rabatt auf alle soundstarken Bluetooth-Speaker und Kopfhörer von Teufel. Der Coupon ist gültig bis zum 31.01.2020 und nur im Teufel Onlineshop www.teufelaudio.at einlösbar. Der 10 % Preisnachlass gilt auf den Warenwert von Produkten der Kategorien „Kopfhörer“ und „Bluetooth“, jedoch nicht für B-Ware, Kaufgutscheine, Merchandise und Sondereditionen. Versandkosten zählen nicht zum Warenwert. Nicht gültig für bereits getätigte Käufe. Kann nicht in Kombination mit anderen Aktionsgutscheinen eingelöst werden. Es gelten die <a href="https://www.teufelaudio.at/agb.html" target="_blank" rel="noopener noreferrer">allgemeinen Geschäftsbedingungen</a> der Lautsprecher Teufel GmbH, Budapester Straße 44, 10787 Berlin. Der Gewinn wird von der Lautsprecher Teufel GmbH gestellt.</p>
        <p>3 € Rabattgutschein für mjam:<br/>Gutschein online auf mjam.at oder über die mjam App einmalig einlösbar bis zum 31.01.2020. Nur ein Gutschein pro Bestellung einlösbar. Mindestbestellwert beträgt 10 €, kann bei einzelnen Restaurants abweichen. Kombination mit anderen Gutscheincodes oder Aktionen nicht möglich. Keine Barauszahlung möglich; Restwert verfällt. Es gelten die Bedingungen unter <a href="https://www.mjam.net/agb/" target="_blank" rel="noopener noreferrer">mjam.at/agb/</a> und <a href="https://mjam.at/datenschutz/" target="_blank" rel="noopener noreferrer">mjam.at/datenschutz/</a>. Der Gewinn wird von der mjam GmbH gestellt.</p>
        <p>2 € Rabattgutschein für mjam:<br/>Gutschein online auf mjam.at oder über die mjam App einmalig einlösbar bis zum 31.01.2020. Nur ein Gutschein pro Bestellung einlösbar. Mindestbestellwert beträgt 8 €, kann bei einzelnen Restaurants abweichen. Kombination mit anderen Gutscheincodes oder Aktionen nicht möglich. Keine Barauszahlung möglich; Restwert verfällt. Es gelten die Bedingungen unter <a href="https://www.mjam.net/agb/" target="_blank" rel="noopener noreferrer">mjam.at/agb/</a> und <a href="https://mjam.at/datenschutz/" target="_blank" rel="noopener noreferrer">mjam.at/datenschutz/</a>. Der Gewinn wird von der mjam GmbH gestellt.</p>

        <p><u>Hauptgewinn-Pakete:</u></p>
        <p>Paket 1: “<strong>Etwas mehr für die Familienabende</strong>”</p>
        <p>Dieses Paket beinhaltet:</p>
        <ol>
          <li>Teufel Cinebar Lux<br/>Es handelt sich um eine Cinebar Lux von Teufel mit zwölf High-Performance-Tönern. Lieferung inklusive. Der Gewinn wird von der Lautsprecher Teufel GmbH per Post an den Gewinner versendet. Dieser Gewinn wird von der Lautsprecher Teufel GmbH gestellt.</li>
          <li>Einen Samsung RU7099 138 cm (55 Zoll) LED Fernseher (Ultra HD, HDR, Triple Tuner, Smart TV) [Modelljahr 2019] [Energieklasse A], eine Gadgy Popcorn Maschine (Retro Popcorn Maker, Heißluftbetrieb ohne Fett) exkl. Popcorn-Zutaten, eine Netflix Geschenkkarte im Wert von 100 €, eine Beautissu XXL Kuscheldecke Aurelia 220x240cm ÖKO-TEX in der Farbe grün. Lieferung inklusive. Der Gewinn wird von der mjam GmbH per Post an den Gewinner versendet. Dieser Gewinn wird von der mjam GmbH gestellt.</li>
        </ol>

        <p>Paket 2: “<strong>Etwas mehr für die Romantiker</strong>”</p>
        <p>Dieses Paket beinhaltet:</p>
        <ol>
          <li>Ein Bundle im Wert von ca. 750 €, bestehend aus einem refurbed™ iPhone X (Spacegrau | 64GB | 18 Monate Garantie), einem passenden Panzerglas™, einer passenden refurbed™ Handyhülle und einem persönlichen Baumzertifikat wird dir per Post von mjam GmbH zugesandt. Zur Garantieabwicklung werden deine Daten an refurbed GmbH übermittelt. Keine Barablöse. Lieferung inklusive. Es gelten die <a href="https://www.refurbed.at/tos/" target="_blank" rel="noopener noreferrer">allgemeinen Geschäftsbedingungen</a> der Refurbed GmbH, Windmühlgasse 30/12, 1060 Wien.</li>
          <li>Ein Cilio Schokoladen Fondue-Set 6-teilig (Ø 12 cm, Porzellan) und eine Infinity Flowerbox Small (Schwarz) mit 4 echten Premiumrosen in Vibrant Red. Lieferung inklusive. Der Gewinn wird von der mjam GmbH per Post an den Gewinner versendet. Dieser Gewinn wird von der mjam GmbH gestellt.</li>
        </ol>

        <p>Paket 3: “<strong>Etwas mehr für die Gestressten</strong>”</p>
        <p>Dieses Paket beinhaltet:</p>
        <ol>
          <li>Einen Gutschein im Wert von 650 € von THL Thermenhotel Loipersdorf GmbH & Co KG für 2 Übernachtungen im Premium Deluxe Zimmer für 2 Personen mit Halbpension (Frühstück, Abendbuffet), Minibar (alkoholfreie Getränke, einmalige Füllung am Anreisetag), Eintritt in die Therme Loipersdorf von Anreisetag 9 Uhr bis Abreisetag 21 Uhr, Zutritt zum hauseigenen Spa & Wellnessbereich, Willkommensgetränk und 1x Sonnreich Badetasche mit 2 Badehandtüchern und 2 Paar Badeschuhen.<br/>
          Der Gewinn wird von der THL Thermenhotel Loipersdorf GmbH & Co KG, Schaffelbadstraße 219, A 8282 Loipersdorf, gestellt. Gutschein wird dem Gewinner per Post von der mjam GmbH zugestellt. Gültigkeit ist immer 1 Jahr ab Ausstellung. Einzulösen auf Anfrage und Verfügbarkeit der Zimmer im Das Sonnreich Thermenhotel Loipersdorf. Reservierungen bitte unter der Telefonnummer: +43 3382 20 000.<br />
          Eine vorherige Reservierung und Bekanntgabe der Gutscheinnummer ist in jedem Fall erforderlich. Bitte beachte, dass nur ein beschränktes Zimmer-Kontingent verfügbar ist, und daher trotz vorhandener freier Zimmer bei Buchungsplattformen das Kontingent für bestimmte Termine bereits erschöpft sein kann. Das Hotel behält sich vor, das auf dem Gutschein ausgewiesene Arrangement zu ändern oder durch ein anderes zu ersetzen. Dieser Gutschein kann nicht in bar abgegolten werden. Der Gutschein ist an Feiertagen, verlängerten Feiertags-Wochenenden über Silvester sowie im Zeitraum vom 26.12. bis 07.01. nicht einlösbar und kann nach Ablauf der Gültigkeit nicht weiter verlängert werden. Inklusive Lieferung. Der Gewinn wird von der mjam GmbH per Post an den Gewinner versendet.</li>
          <li>7 Stück Garnier Hydra Bomb Tuchmasken Set (Gesichtsmaske mit Hyaluronsäure), eine Yankee Candle Duftkerze im großen Jar, Duft: Cranberry Ice. Lieferung inklusive. Der Gewinn wird von der mjam GmbH per Post an den Gewinner versendet. Dieser Gewinn wird von der mjam GmbH gestellt.</li>
        </ol>

        <p>Paket 4: “<strong>Etwas mehr für Miss Tropicana 2019</strong>”</p>
        <p>Dieses Paket beinhaltet:</p>
        <ol>
          <li>Einen Gutschein im Wert von 3.000 € von TUI. Gutschein hat eine Gültigkeit von 3 Jahren. Gutschein kann in jedem Reisebüro mit dem TUI Zeichen in Deutschland und Österreich oder online über www.tui.com eingelöst werden. Eine Einlösung kann nur für die Programme des Reiseveranstalters TUI Deutschland GmbH mit den Marken TUI, airtours oder Gulet erfolgen. Wird der Gutschein direkt bei Buchung der Reise im Reisebüro vorgelegt bzw. der Code genannt, wird dieser sofort verrechnet. Der Gutschein kann auch nachträglich per E-Mail, per Post oder telefonisch eingelöst werden und wird dann mit der Restzahlung der Reise verrechnet. Bei einem Reisepreis unterhalb des Gutscheinwertes verbleibt der Restbetrag auf dem Gutschein. Der Status des Gutscheincodes kann über www.tuigutscheine.com abgefragt werden. Der Gutschein darf nicht weiterverkauft werden. Keine Barauszahlung möglich. Gewinn nicht übertragbar. Es gelten die <a href="https://www.tui.com/service-kontakt/agb-und-allgemeine-geschaeftsbedingungen/" target="_blank" rel="noopener noreferrer">aktuellen Geschäftsbedingungen</a> der TUI Deutschland GmbH. Inklusive Lieferung. Dieser Gewinn wird von der mjam GmbH gestellt.</li>
          <li>Relaxdays Partybrille Hawaii (Sonnenbrille mit Palme u. Cocktail in grün), einmal SUNDANCE Sonnenspray sensitive LSF 50 (200 ml) und eine aufblasbare Luftmatratze Bestway Pizza Party Lounge. Lieferung inklusive. Dieser Gewinn wird von der mjam GmbH gestellt.</li>
        </ol>

        <h3>5. Gewährleistung</h3>
        <p>Die mjam GmbH übernimmt für die verlosten Gewinne, welche nicht von der mjam GmbH selbst erbracht werden, keine Gewährleistung für Sachmängel und fehlende Eigenschaften. Dies gilt nicht soweit die mjam GmbH einen solchen Mangel arglistig verschwiegen oder eine Garantie übernommen hat. Etwaige Gewährleistungsansprüche tritt die mjam GmbH an den jeweiligen Gewinner ab.</p>
        <p>mjam GmbH ist nicht verantwortlich für unwesentliche Abweichungen bezüglich Angaben zum Gewinn. In Abweichung hiervon gelten die gesetzlichen Gewährleistungsrechte für die mjam 3 €- oder 2 €-Essensgutscheine.</p>

        <h3>6. Datenschutz</h3>
        <p>Die mjam GmbH erhebt, speichert und nutzt Ihre Adressdaten für die Durchführung des Gewinnspiels.</p>
        <p>Zur Abwicklung der Preisvergabe (Zusendung an die Gewinner per E-Mail oder Post) und der Garantieabwicklung ist jedoch die Weiterleitung einzig zu diesem Zweck an den Versender (Lautsprecher Teufel GmbH und Refurbed GmbH) notwendig. Mit Teilnahme an dem Gewinnspiel und der Annahme des Preises stimmen Sie der Datenweitergabe zu diesem Zwecke zu. Im Übrigen gelten bei Bestellungen die Datenschutzbestimmungen der mjam GmbH, die mit jeder Bestellung akzeptiert werden.</p>

        <h3>7. Sonstiges</h3>
        <h4>Der Rechtsweg ist ausgeschlossen.</h4>
        <p>Sollte ein Gewinn vom Gewinner nicht fristgemäß angenommen werden, behält sich die mjam GmbH vor, soweit in diesen Teilnahmebedingungen nichts anderes bestimmt ist, im Einzelfall zu entscheiden, ob eine Neuverlosung stattfindet.</p>
        <p>Teilnehmer, die versuchen, sich Gewinne zu erschleichen (z.B. durch Spam o.Ä.) können vom Gewinnspiel ausgeschlossen werden. Eventuell erschlichene Gewinnansprüche verfallen in einem solchen Fall.</p>
        <p>mjam GmbH behält es sich vor, den Ablauf und/oder die Teilnahmebedingungen dieses Gewinnspiels zu verändern oder dieses Gewinnspiel vorzeitig zu beenden, wenn die ordnungsgemäße Durchführung nicht mehr gewährleistet werden kann.</p>

      </SectionInner>
    </SectionRules>
    <Footer noSearch />
  </Layout>
}